<template>
    <div class="video-page">
      <div class="video-body">
          <h1>{{ obj.title }}</h1>
          <div class="base-info">
              <span>{{ obj.createTime }}</span>
          </div>
          <template v-if="(obj.videoUrl).includes('www.youtube.com')">
              <iframe class="video" :src="obj.videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </template>
          <template v-else>
              <img class="video" :src="obj.videoUrl" alt="">
          </template>
          <!-- <div class="describe">
              <p>{{ obj.described }}</p>
          </div> -->
          <div class="section-title">{{ $i18n.t('training.documentation') }}</div>
          <div class="documents">
              <div class="document" v-for="(item) in docs" :key="item.id">
                  <div class="resouse-name">{{ item.title }}</div>
                  <div class="resouse-link" @click="download(item.docUrl)">{{ $i18n.t('productDetail.clicktoLoad' )}}</div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import {getVideo, getDocByVideoId} from '@/api/trainingApi'
  export default {
      name: 'CspVideo',
      data() {
          return {
              videoId: '',
              obj: {
                videoUrl: ''
              },
              docs: [],
              browseTime: 0,  // 浏览时长初始值为 0
              clearTimeSet: null,
              cumulativeDuration: 0,
          }
      },
      created() {
          let {videoId} = this.$route.params;
          this.videoId = videoId;
      },
      async mounted() {
          await this.checkAccess()
          await this.getData()
      },
      methods: {
          download(value) {
              window.open(value)
          },
          async getDocByVideoId() {
              const {data: {data, isSuccess}} = await getDocByVideoId({
                videoId: this.videoId
              })
              if (isSuccess) {
                  this.docs = data;
              }
          },
          async getData() {
              const {data: {data, isSuccess}} = await getVideo({
                  id: this.videoId
              })
              if (isSuccess) {
                  this.obj = data;
                  this.getDocByVideoId()
              }
          },
          async checkAccess() {
              const account = this.$store.state.client.user.account;
              if (!account) {
                this.$router.push({
                    path: `/login`
                })
                return false;
              }
          },
      }
  }
  </script>
  
  <style lang="less" scoped>
  .video-page {
      height: inherit;
      padding-top: 30px;
      border-top: 1px solid rgb(230 239 243);
      .video-body {
          width: 1080px;
          margin: auto;
          h1 {
              font-size: 22px;
              line-height: 34px;
              font-weight: 500;
              margin-bottom: 6px;
          }
          .base-info {
              font-size: 13px;
              color: #AEB3B9;
              margin-bottom: 12px;
          }
          .video {
              width: 100%;
              height: 619px;
          }
          .describe {
              margin-top: 20px;
          }
          .section-title {
              height: 27px;
              line-height: 27px;
              font-size: 20px;
              color: #000;
              border-left: 6px solid #c7000b;
              padding-left: 32px;
              margin-bottom: 30px;
              margin-top: 60px;
          }
          .documents {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .document {
                  width: 100%;
                  height: 40px;
                  padding-left: 40px;
                  // padding-right: 60px;
                  position: relative;
                  display: flex;
                  align-items: center;
                  // justify-content: space-between;
                  &::before {
                      position: absolute;
                      left: 0px;
                      top: 15px;
                      content: '';
                      width: 10px;
                      height: 10px;
                      background-color: gray;
                      border-radius: 50%;
                  }
                  margin-bottom: 20px;
                  .resouse-name {
                      font-size: 16px;
                      width: 500px;
                  }
                  .resouse-link {
                      cursor: pointer;
                      border: 1px solid #c7000b;
                      padding: 5px 11px 5px 11px;
                      border-radius: 224px;
                      color: #c7000b;
                      font-size: 16px;
                  }
              }
          }
      }
  }
  </style>