import axiosApi from '@/api/axiosApi';

export const getFilter = () => {
    return axiosApi({
        method: 'GET',
        url: '/intserv/train/getFilter'
    })
}

export const getVideosByRelevance = (data) => {
    return axiosApi({
        method: 'POST',
        url: '/intserv/train/getVideosByRelevance',
        data
    })
}

export const getVideosByTitle = (data) => {
    return axiosApi({
        method: 'POST',
        url: '/intserv/train/getVideosByTitle',
        data
    })
}

export const getVideo = (data) => {
    return axiosApi({
        method: 'POST',
        url: '/intserv/train/getVideo',
        data
    })
}

export const getDocByVideoId = (data) => {
    return axiosApi({
        method: 'POST',
        url: '/intserv/train/getDocByVideoId',
        data
    })
}